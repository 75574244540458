// Couleur primaire  AVG

$vert: #009455;
$noir: #252122;
$orange: #f6c25c;

$propositionVert1: #9ddd92;
$propositionVert2: #6fca85;

// Couleur secondaire AVG

$vert-pomme: #97a861;
$vert-claire: #d5e3a6;
$gris: #828184;
$marron: #7c3c19;
$violet: #663366;
$bleu: #3d4666;

// Autre

$blanc: #fff;
$gris-standard: #828184e2;
$gris-cool: #504b4b41;
$bleu-ciel : rgba(76, 214, 245, 0.918);

//Polices

$font-rockwell: "Rockwell", "Courier New", Courier, monospace;
$font-calibri: "Calibri", sans-serif;
