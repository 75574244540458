@import "./src/components/scss/variables";

.swiper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100%;
  background: #333;
  //box-shadow: 0 10px 30px -10px $noir;
}
.my-link {
  text-align: center;
  text-decoration: none;
  height: auto;
  white-space: nowrap;
  display: block;
  font-size: 1em;
  font-weight: 500;
  border-radius: 16% 84% 17% 83% / 100% 0% 100% 0% !important;
  border: none;
  outline: none;
  color: $noir;
  &:hover {
    cursor: pointer;

  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: none !important;
  color: $vert !important;
}

.swiper-pagination-bullet {
  background-color: $vert !important;
}

.swiper .swiper-slide {
  position: relative;
  height: 600px;
}

.swiper .numbertext {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  font-size: 1.5rem;
}

.swiper .imageCarousel {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
}
.swiper .imageCarousel2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper .text {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 30px 0 30px 0;
  width: 30%;
  background-color: #fff;
  color: #000;
  text-shadow: 6px 6px 8px #fff;
  box-shadow: 0 10px 10px 10px $noir;
  padding: 2%;
  font-size: 1.2rem;
  font-weight: 600;
}

.swiper .text i {
  color: $vert;
  font-size: 1.3rem;
  font-weight: 600;
}

@media screen and (max-width: 1215px) {
  .swiper {
    top: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    background-color: #fff !important;
    box-shadow: none;
    .imageCarousel {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
      position: relative;
    }
    .imageCarousel2 {
      object-fit: fill;
      height: 100%;
      width: 100%;
      position: relative;
      
    }

  .text {
  right: 0;
  border-radius: 30px 0 30px 0;
  position: absolute;
  bottom: 0;
  width: 60%;
  background-color: rgb(255, 255, 255, 0.7);
  color: #000;
  text-shadow: 6px 6px 8px #fff;
  box-shadow: 0 10px 20px 10px $noir;
  padding: 3%;
  font-size: 1.2rem;
  font-weight: 600;
      i {
        text-shadow: 6px 6px 8px $vert-claire;
        color: $vert;
        font-size: 1.2em;
        font-weight: 600;
      }
    }
  }

}

@media screen and (max-width: 850px) {
  .swiper {
    top: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    background-color: #fff !important;
    box-shadow: none;
    .imageCarousel {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
      position: relative;
    }
    .imageCarousel2 {
      object-fit: fill;
      height: 100%;
      width: 100%;
      position: relative;
      
    }

  .text {
  align-items: center;
  text-align: start;
  border-radius: 30px 0 30px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgb(255, 255, 255, 0.6);
  color: #000;
  text-shadow: 6px 6px 8px #fff;
  box-shadow: 0 10px 20px 10px $noir;
  padding: 4%;
  font-size: 1.2rem;
  font-weight: 600;
      i {
        text-shadow: 6px 6px 8px $vert-claire;
        color: $vert;
        font-size: 1.2em;
        font-weight: 600;
      }
    }
  }

}
