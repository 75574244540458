// Chargement.scss
.corps{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 100vh;
    overflow: hidden;
    background-color: #f2f2f2;
.image-logo {
    text-align: center;
    animation: bounce 1s infinite;
    width: 100px; /* Ajustez la taille de l'image en conséquence */
    height: 100px; /* Ajustez la taille de l'image en conséquence */
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  } 
}