* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quicksand", sans-serif;
}

p {
  font-family: "Nunito Sans", sans-serif;
}

span {
  font-family: "Quicksand", sans-serif;
}
